import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import AppPlayGround from './app-play-ground';

ReactDOM.render(
  // <App />,
  <AppPlayGround />,
  document.querySelector('#root')
);
